import React from 'react';
import './ProfileCard.css'; // Make sure to create a corresponding CSS file


const isMobile = () => window.innerWidth <= 768;


const ProfileCard = ({ imageUrl, name, title, company, description, email, phone }) => {
  return (
    <div style={styles.cardContainer}>
      <div style={styles.imageContainer}>
        <img 
          src={imageUrl}
          alt="Profile" 
          style={styles.profileImage}
        />
      </div>
      <div style={styles.detailsContainer}>
        <h1 style={styles.name}>{name}</h1>
        <h2 style={styles.title}>{title}</h2>
        <p style={styles.company}>{company}</p>
        <p style={styles.description}>{description}</p>
        <p style={styles.contact}>Email: {email}</p>
        <p style={styles.contact}>Phone: {phone}</p>
      </div>
    </div>
  );
};

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#eee',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.6)',
    padding: '20px',
    borderRadius: '10px',
    fontFamily: '"Montserrat","Open Sans","Helvetica Neue","Helvetica","sans-serif"',
    maxWidth: '800px'
  },
  imageContainer: {
    flex: '0 0 auto',
    marginRight: '20px'
  },
  profileImage: {
    width: isMobile() ? '100px' : '150px',
    height: isMobile() ? '100px' : '150px',
    borderRadius: '50%'
  },
  detailsContainer: {
    flex: '1 1 auto',
    textAlign: 'left'
  },
  name: {
    margin: '0',
    fontWeight: 'bold',
    fontSize: '1.5em'
  },
  title: {
    margin: '0',
    fontWeight: 'normal',
    fontSize: '1.2em',
    color: '#666'
  },
  company: {
    margin: '0',
    fontWeight: 'normal',
    fontSize: '1em',
    color: '#666'
  },
  description: {
    marginTop: '20px',
    fontSize: '0.9em'
  },
  contact: {
    fontSize: '0.9em',
    color: '#333'
  }
};

export default ProfileCard;

