// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card {
    width: 300px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
    text-align: center;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .title {
    color: grey;
    font-size: 18px;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  /* Mobile-friendly adjustments */
@media (max-width: 768px) {
  .profile-card {
    width: 90%; /* More responsive width */
    height: 30%;
    padding: 0px; /* Slightly reduced padding */
    
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.548);
    border-radius: 10px;
    background: #fff;
    text-align: center;
  }

  .profile-image {
    width: 60px; /* Slightly smaller image */
    height: 30px;
    margin-bottom: 50px;
  }

  .title {
    font-size: 12px; /* Slightly smaller font size */
  }

  .contact-info {
    margin-top: 15px; /* Adjusted spacing */
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uCAAuC;IACvC,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA,gCAAgC;AAClC;EACE;IACE,UAAU,EAAE,0BAA0B;IACtC,WAAW;IACX,YAAY,EAAE,6BAA6B;;IAE3C,yCAAyC;IACzC,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,WAAW,EAAE,2BAA2B;IACxC,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,eAAe,EAAE,+BAA+B;EAClD;;EAEA;IACE,gBAAgB,EAAE,qBAAqB;EACzC;AACF","sourcesContent":[".profile-card {\r\n    width: 300px;\r\n    padding: 20px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n    border-radius: 10px;\r\n    background: #fff;\r\n    text-align: center;\r\n  }\r\n  \r\n  .profile-image {\r\n    width: 100px;\r\n    height: 100px;\r\n    border-radius: 50%;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .title {\r\n    color: grey;\r\n    font-size: 18px;\r\n  }\r\n  \r\n  .contact-info {\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  /* Mobile-friendly adjustments */\r\n@media (max-width: 768px) {\r\n  .profile-card {\r\n    width: 90%; /* More responsive width */\r\n    height: 30%;\r\n    padding: 0px; /* Slightly reduced padding */\r\n    \r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.548);\r\n    border-radius: 10px;\r\n    background: #fff;\r\n    text-align: center;\r\n  }\r\n\r\n  .profile-image {\r\n    width: 60px; /* Slightly smaller image */\r\n    height: 30px;\r\n    margin-bottom: 50px;\r\n  }\r\n\r\n  .title {\r\n    font-size: 12px; /* Slightly smaller font size */\r\n  }\r\n\r\n  .contact-info {\r\n    margin-top: 15px; /* Adjusted spacing */\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
