import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/images/bg.jpg';
import logoImage from '../assets/images/logo.png';
import Disclaimer from '../components/Disclaimer';
import '../pages/DefaultText.css';





const HomePage = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  // Effect hook to add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Call isMobile() here to get a fresh check each time the component renders
  const buttonStyle = {
    fontSize: isMobileView ? '15px' : '20px',
    padding: '15px 20px',
    backgroundColor: '#092D7E',
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    width: isMobileView ? '100px' : '300px',
    margin: isMobileView? '20px' : '100px',
    fontWeight: 'bold',
    fontFamily: '"Noto Serif", serif',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
  };

const backgroundStyle = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: isMobileView ? '800px' :  '1000px', // full height of the viewport
};

const logoStyle = {
  width: isMobileView ? '50%' : '20%',
  height: 'auto',
  position: 'absolute',
  top: isMobileView ? '20%' : '10%', // 10% from the top of the page
  left: '50%', // centered horizontally
  transform: 'translate(-50%, 0)', // adjust for the width of the image
};

const buttonContainerStyle = {
  position: 'absolute',
  top:  isMobileView ? '50%' :  'calc(10% + 20% + 15%)', // below the logo image
  width: '100%',
  textAlign: 'center',
};

const Button = ({ link, text }) => {
  return (
    <a href={link} style={buttonStyle}>
      {text}
    </a>
  );
};

  return (
    <div >

    <div style={backgroundStyle}>
      <img src={logoImage} alt="Logo" style={logoStyle} />
      <div style={buttonContainerStyle}>
        <Button link="/om-comcap" text="OM COMCAP" />
        <Button link="/partner" text="PARTNER" />
      </div>
    </div>
    <Disclaimer className="textnormal"></Disclaimer>
          </div>

  );
};

export default HomePage;
