import React from 'react';
import './Navigation.css'; // Your styles should be defined here
import backgroundImage from '../assets/images/bg.jpg'; // Import your background image
import logoImage from '../assets/images/logo.png'; // Correct path to your logo image
import { Link } from 'react-router-dom'; // Import if you're using React Router

function Navigation() {
    // First, define a function to check if the window width is less than a mobile breakpoint (e.g., 768px)
const isMobile = () => window.innerWidth <= 768;

// Then, you can adjust your linkStyle based on the screen width
const linkStyle = {
    width: isMobile() ? '100%' : '231px', // Full width on mobile or specified width otherwise
    height: '29px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: isMobile() ? '13px' : '16px', // Adjusted font size for mobile
    color: '#092D7E',
    textTransform: 'uppercase',
    margin: isMobile() ? '0 5px' : '0 10px', // Adjusted margin for mobile
    textDecoration: 'none',
    whiteSpace: 'nowrap', // Prevents the text from wrapping
    maxWidth: '100%'
};


// Remember to re-evaluate the style on window resize if necessary
// This can be achieved by adding an event listener to the window object and updating the component state accordingly

    

    return (
            


        <div style={{ position: 'relative', width: '100%', height: isMobile() ? '225px' : '425px', marginTop: isMobile() ? '0px' : '50px' }}>
                    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans&display=swap" rel="stylesheet"/>

    <div style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        opacity: 0.75,
        width: '100%',
        height: isMobile() ? '200px' : '400px', // Adjust based on screen size
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        maxWidth: '100%',

    }} />
    <nav style={{ 
        width: '100%',
        height: isMobile() ? '200px' : '400px', // Adjust based on screen size
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
    }}>
        <img src={logoImage} alt="Logo" style={{
            width: isMobile() ? '150px' : '400px', // Dynamic width based on screen size
            position: 'relative',
            top: isMobile() ? '10px' :'-15px',
            marginBottom: '20px',
            maxWidth: '100%',
        }} />

        <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        }}>
                <a href="/om-comcap" className="navLink" style={linkStyle} >Om ComCap</a>
                <a href="/partner" className="navLink" style={linkStyle}>Partner</a>
                <a href="/styret" className="navLink" style={linkStyle}>Styret</a>
                <a href="/foretaksstyring" className="navLink" style={linkStyle}>Foretaksstyring</a>
            </div>
            </nav>
        </div>
    );
}

export default Navigation;
