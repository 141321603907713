import React from 'react';
import './QuoteComponent.css'; // Assuming you have a CSS file for styling



const QuoteComponent = ({ imageSrc, diameter, textList }) => {
  const isMobile = () => window.innerWidth <= 768;

  const circleStyle = {
    width: isMobile ? '240px': diameter,
    height: isMobile ? '200px': diameter,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'inline-block',
    backgroundImage: `url(${imageSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div>

    <div className="quote-container">
      <div style={circleStyle}></div>
      <div className="text-container">
        {textList.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    </div>
    </div>

  );
};

export default QuoteComponent;
