import React from 'react';
import Navigation from '../components/Navigation';
import ProfileCard from '../components/ProfileCard';
import Disclaimer from '../components/Disclaimer';
import Rockefeller from '../assets/images/Rockefeller.png';
import QuoteComponent from '../components/QuoteComponent/QuoteComponent';
import './DefaultText.css';

const centeredContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const Partner = () => {
    // Component logic and JSX goes here
    const isMobile = () => window.innerWidth <= 768;

    const divMargin = {
        marginTop: isMobile() ? '5%' : '5%',  
        marginBottom: isMobile() ? '5%' : '20%',   
    
    };

    return (
        <div>
        <div>
          <Navigation />
               <h1 className="boldcentered">Handelsoppdatering FY-2024</h1>
               <p></p>

            <p className="textnormalcentered">Sendes til partnere i løpet av januar 2025.</p>
            <p className="textnormalcentered"><strong>Spørsmål?</strong> Kontakt oss på: <strong>Partner@comcap.no</strong></p>

  
            <p></p>
            <div style={divMargin}> 




        </div>
        <div style={{ display: 'flex', justifyContent: isMobile() ?'left': 'center', padding: isMobile() ? '20px' : '50px'} } className="textnormal">
<QuoteComponent
  imageSrc={Rockefeller}
  diameter="275px"
  textList={['«Jeg tror ikke det er noen annen kvalitet som er så viktig for suksess av noe slag som utholdenhet. Den overvinner nesten alt, til og med naturen.»', '- John D. Rockefeller, Oljemagnat']}/>
        </div>

        </div>
        <div>
        <Disclaimer></Disclaimer>
        </div>
        </div>
    );
}

export default Partner;