import React from 'react';
import Navigation from '../components/Navigation';
import ProfileCard from '../components/ProfileCard';
import Disclaimer from '../components/Disclaimer';
import './DefaultText.css';
import Vedtekter2023 from '../assets/reports/Vedtekter-2023.pdf';
import Honorar2023 from '../assets/reports/Retningslinjer-Godtgjørelse-2023.pdf';

function BulletPointList() {
    return (
      <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
        <li>Bullet Point 1</li>
        <li>Bullet Point 2</li>
        <li>Bullet Point 3</li>
      </ul>
    );
  }
  
const isMobile = () => window.innerWidth <= 768;

const Foretaksstyring = () => {
    // Component logic and JSX goes here
    const divMargin = {
        marginTop: '5%',   // 5% top margin
        marginBottom: '20%',   // 5% top margin
    
    };
    const divStyle = {
        marginTop: '10px',   // 5% top margin
        marginLeft: '22.5%',
        marginRight: '22.5%',
        textAlign: 'left',
        fontFamily: '"Montserrat","Open Sans","Helvetica Neue","Helvetica","sans-serif"', // Montserrat font
        lineHeight: 1.8, // Line spacing
        fontSize: '16px', // Text size // Add Montserrat font
        color: '#666', // Dark grey text color
        letterSpacing: '0.5px', // Slight increase in letter spacing
        padding: '10px 0', // Adds vertical padding to the div
        marginBottom: '5px',   // 5% top margin

    };
    const listStyle = {
        listStyleType: 'circle', // This makes the bullet points circular
        textAlign: 'left',       // Ensures text alignment is to the left
        marginLeft: '25%',      // These two auto margins center the <ul> element
        marginRight: '25%',
        width: 'fit-content',    // This makes the <ul> width as wide as its content
        fontSize: '16px', // Text size // Add Montserrat font
        color: '#666', // Dark grey text color
        fontFamily: '"Montserrat","Open Sans","Helvetica Neue","Helvetica","sans-serif"', // Montserrat font

      };
      const linkStyle = {
        width: isMobile() ? '100%' : '231px', // Full width on mobile or specified width otherwise
        height: '29px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: isMobile() ? '13px' : '16px', // Adjusted font size for mobile
        color: '#092D7E',
        textTransform: 'uppercase',
        margin: isMobile() ? '0 30px' : '0 30px', // Adjusted margin for mobile
        textDecoration: 'none',
        whiteSpace: 'nowrap', // Prevents the text from wrapping
        maxWidth: '100%'
    };
    return (
        <div>
        <div>
          <Navigation />
          <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        }}>
                <a style={linkStyle} href={Vedtekter2023}   >Vedtekter</a>
                <a style={linkStyle} href={Honorar2023} >Retningslinjer Godtgjørelse</a>
  
            </div>

               <h1>Foretaksstyring</h1>
               
                <p className="textnormal">Commandeer Capital AS legger stor vekt på å opprettholde tillit blant aksjeeiere, kapitalmarkeder og andre interessenter. Vi arbeider aktivt med god eierstyring og selskapsledelse.</p>
                
                <h4 className="boldforetak">1. Eierstyring og selskapsledelse</h4>

                    <p className="textnormal">Commandeer Capital AS overholder rapporteringskravene for foretaksstyring i henhold til regnskapsloven § 3-3b. Vi har etablert interne retningslinjer for eierstyring og selskapsledelse som er tilpasset vår virksomhet og strategi.</p>
                    <p className="textnormal">Regnskapsloven er tilgjengelig påwww.lovdata.no, mens “norsk anbefaling for eierstyring og selskapsledelse” er tilgjengelig på www.nues.no.

                    </p>
                    <p className="textnormal">Redegjørelse om foretaksstyring vil bli behandlet på selskapets ordinære generalforsamling.

                    </p>
                    <p className="textnormal">Opplysninger som Commandeer Capital AS plikter å gi etter regnskapsloven § 3-3b om redegjørelse for foretaksstyring, er hensyntatt i denne redegjørelsen og følger anbefalingens systematikk der dette er naturlig.

                    </p>

                <h4 className="boldforetak">2. Virksomhet

                </h4>
                    <p className="textnormal">Commandeer Capital AS sine vedtekter er tilgjengelig på selskapets nettside. Selskapets virksomhet fremkommer av vedtektenes § III:

                    </p>
                    <p className="textnormal">"Selskapet virksomhet består i investeringer, forvaltning, drift og rådgivning samt annen tjenesteyting innenfor industri, energi, eiendom og lignende virksomhet, herunder ved eierskap og investering i annen virksomhet."

                    </p>
                    <p className="textnormal">Det overordnede mål for Commandeer Capital AS virksomhet er å oppnå maksimal vekst i egenkapital pr. aksje, slik at aksjonærene i et langsiktig perspektiv oppnår en avkastning som er konkurransedyktig i forhold til sammenlignbare investeringsalternativer.

                    </p>
                    <p className="textnormal">Selskapets strategi er å erverve, eie og å utvikle investeringer for det lange løp. Selskapet skal investere i bedrifter som gir muligheten til å forrente substansverdi over en lang periode.

                    </p>
                    <p className="textnormal">Forrentelse av substansverdi i porteføljen søkes realisert ved å investere i bedrifter med konservativ kapitalstruktur som opererer i segmenter med lav konkurranse. Dette vil reflekteres i form av høy avkastning på eiendeler, høy brutto og operasjonell margin, lav gjeld-til-egenkapital og høy dekningsgrad av rentekostnader med inntekter.

                    </p>
                    <p className="textnormal">I en kapitalintensiv næring vektlegges det at selskapet skal ha en ubetinget sterk finansiell stilling.

                    </p>
                    <p className="textnormal">Kombinasjonen av høy løpende avkastning på porteføljen og lave driftskostnader, forventes å bidra til maksimal verdistigning både i et kort- og langsiktig perspektiv.

                    </p>
                    <p className="textnormal">Commandeer Capital AS har mål om å skape aksjonærverdier på en bærekraftig måte.

                    </p>
                    <p className="textnormal">Styrets arbeid er omtalt i punkt 9.

                    </p>

                <h4 className="boldforetak">3. Selskapskapital og utbytte</h4>
                    <p className="textnormal">Commandeer Capital AS opererer i en kapitalintensiv bransje, og det vektlegges at konsernet skal ha en ubetinget sterk finansiell posisjon.

                    Styret foretar løpende en vurdering av kapitalsituasjonen i lys av selskapets mål, strategi og ønsket risikoprofil.</p>
                    <p className="textnormal">Styret fremmer forslag om utbytte for generalforsamlingen.

                    </p>
                    <p className="textnormal">Styret legger til grunn en forsiktig utbyttepolitikk hvor utbyttestørrelsen tilpasses konsernets investeringsplaner og målsettinger om høy egenkapitalandel og betydelige likviditetsreserver.

                    </p>
                    <p className="textnormal">Commandeer Capital AS har en preferanse for å reinvestere overskuddet i virksomheten når det er mulighet for å skape lønnsom vekst. Vi vil derfor ikke planlegge utbytte dersom vi mener at reinvestering av midlene vil generere mer verdi for selskapet og aksjeeierne.

                    </p>
                    <p className="textnormal">Selskapets styre har årlig på generalforsamling fått fullmakt til å erverve egne aksjer. Per 31.12.2022 har Commandeer Capital ingen egne aksjer.

                    </p>
                    <p className="textnormal">Selskapets styre har årlig også fått løpende fullmakt til å foreta kapitalforhøyelse i Commandeer Capital AS, med anledning til å fravike aksjeeiernes fortrinnsrett til å tegne aksjer.

                    </p>
                    <p className="textnormal">Slik fullmakt muliggjør enklere saksbehandling dersom det skulle oppstå muligheter for å videreutvikle konsernets virksomhet ved erverv av aktiva, virksomhet eller selskapsandeler mot vederlag i nytegnede aksjer.

                    </p>
                    <p className="textnormal">Styret praktiserer en restriktiv utøvelse av slike fullmakter.

                    </p>

                    <p className="textnormal">Styrefullmaktene har hatt gyldighet frem til neste ordinære generalforsamling. </p>
                   
                <div>       

                <h4 className="boldforetak">4. Likebehandling av aksjeeiere og transaksjoner med nærstående                </h4>

                    <p className="textnormal"> Commandeer Capital AS har to aksjeklasser. Hver A- aksje gir én stemme mens hver B-aksje gir ingen stemmerett. A-aksjer har også en preferanserett på utbytter, gitt at visse betingelser formulert i vedtekt §VI er tilfredsstilt. Begge aksjeklasser gir for øvrig like rettigheter ellers i selskapet, herunder retten til å delta på generalforsamling.

                    </p>
                    <p className="textnormal"> Ved aksjekapitalforhøyelser skal eksisterende aksjonærer gis fortrinnsrett, med mindre særskilte forhold tilsier at dette fravikes. Slik fravikelse vil i så fall blir begrunnet.

                    </p>
                    <p className="textnormal">  Selskapets transaksjoner i egne aksjer skal foretas til bokførte verdier. Dersom likviditeten i aksjen er begrenset i perioden før transaksjon skal finne sted, skal kravet til likebehandling vurderes ivaretatt på andre måter.

                    </p>
                    <p className="textnormal">  Selskapet har retningslinjer som fastsetter meldeplikt for styremedlemmer og ledende ansatte hvis de direkte eller indirekte har en vesentlig interesse i avtaler som inngås av selskapet.

                    </p>
                    <p className="textnormal"> Vesentlige transaksjoner med nærstående parter vil fremgå av note til årsregnskapet. Transaksjonene skal inngås på markedsmessige betingelser, som om de var gjennomført mellom uavhengige parter.

                    </p>
                    <p className="textnormal">Ved ikke uvesentlige transaksjoner mellom Commandeer Capital og aksjeeier, styremedlem, ledende ansatte eller nærstående av disse, vil styret sørge for at det foreligger en verdivurdering fra en uavhengig tredjepart.</p>

                </div>
                <div>       
                <h4 className="boldforetak">5. Aksjer og omsettelighet                </h4>


                    <p className="textnormal">Aksjene i Commandeer Capital er fritt omsettelige gitt at visse krav beskrevet i vedtekt §IX oppfylles.</p>
                    <p className="textnormal">Vedtektene inneholder retningslinjer for hvordan aksjene i Commandeer Capital skal omsettes.</p>


                </div>
                <div>
                    <h4 className="boldforetak">6. Generalforsamling</h4>
                        <p className="textnormal">Øverste myndighet i Commandeer Capital AS er generalforsamlingen, som representerer Commandeer Capital AS sine aksjonærer. Styret legger til rette for at aksjeeiere skal kunne delta på selskapets generalforsamling, blant annet ved å påse at:</p>

                        <ul style={listStyle}>
                            <li>Innkalling og påmeldingsskjema sendes aksjonærene og publiseres på selskapets nettside senest 7 dager før generalforsamlingen avholdes.</li>
                            <li>Saksdokumentene gir tilstrekkelig informasjon til at aksjeeiere kan ta stilling til saker som skal behandles.</li>
                            <li>Påmeldingsfrist settes så nær møtet som mulig, vanligvis 3 dager før.</li>
                        </ul>
                <p className="textnormal">Styret er til stede på generalforsamlingen.</p>
                <p className="textnormal">Aksjeeiere som ikke kan delta på generalforsamlingen kan stemme ved fullmektig. Fullmaktsskjema følger innkallingen. Dokumenter som skal behandles på generalforsamling publiseres på selskapets hjemmeside og den enkelte aksjonær kan kreve disse saksdokumenter tilsendt.</p>
                <p className="textnormal">Selskapets vedtekter inneholder ikke bestemmelse om møteleder på generalforsamlingen. I tråd med Aksjelovens bestemmelser åpnes generalforsamlinger av styrets leder, og møteleder velges deretter av generalforsamlingen.</p>

                </div>

                <h4 className="boldforetak">7. Valgkomité                </h4>

                <p className="textnormal">Selskapet har ikke vedtektsfestet valgkomite. Kandidater til selskapets styre foreslås direkte av selskapets eiere til generalforsamlingen.</p>

                <h4 className="boldforetak">8. Styrets sammensetning og uavhengighet                </h4>

                <p className="textnormal">Styret og generalforsamlingen ivaretar styring og tilsyn med forvaltningen av selskapet.</p>
                <p className="textnormal">Styret i Commandeer Capital skal i henhold til vedtektene bestå av 3 - 7 medlemmer som velges for 2 år av gangen. Styrets leder velges av generalforsamlingen.</p>
                <p className="textnormal">Styret i Commandeer Capital AS består av 3 medlemmer. Selskapet avviker fra anbefaling om uavhengig styremedlemmer, og anser det som fordelaktig gitt selskapets situasjon og virksomhet.</p>
                <p className="textnormal">Styremedlemmene oppfordres til å eie aksjer i selskapet. Oversikt over styremedlemmers aksjeeie fremgår av note til selskapets årsregnskap.</p>
                <p className="textnormal">Styrets samlede kompetanse antas å bidra positivt til selskapets utvikling og til å ivareta aksjonærenes interesser på en tilfredsstillende måte.</p>
                <p className="textnormal">En nærmere presentasjon av styrets medlemmer finnes i årsrapporten og på selskapets nettside www.comcap.no.</p>
                <p className="textnormal">Selskapet har ikke etablert bedriftsforsamling.</p>

                <h4 className="boldforetak">9. Styrets arbeid                </h4>

                <p className="textnormal">Styret har det overordnede ansvar for forvaltningen av Commandeer Capital AS og gjennomføring av selskapets strategi. Styrets oppgaver omfatter også overvåkning og kontroll av selskapets virksomhet, herunder ansvar for at virksomheten organiseres og drives innenfor lovens rammer.</p>
                <p className="textnormal">Styret ansetter og utøver instruksjonsrett overfor administrerende direktør, som er ansvarlig for selskapets daglige drift.</p>
                <p className="textnormal">Styret avholder møter ved behov, dog minimum 5 ganger årlig. Daglig leder sender ut en månedlig driftsrapport til styret hver måned som går gjennom nøkkelhendelser og selskapets finansielle stilling for måneden før.</p>
                <p className="textnormal">Administrerende direktør har rett og plikt til å delta i behandlingen av styrets saker og til å uttale seg, med mindre styret i enkeltsaker har besluttet noe annet.</p>
                <p className="textnormal">Styret drøfter ved behov egen arbeidsform og prosessene rundt forberedelse og gjennomføring av styremøtene, samt sin samlede kompetanse og kapasitet i forhold til selskapets virksomhet.</p>
                <p className="textnormal">Selskapets revisjonsutvalg består av to av styrets medlemmer.</p>

                <h4 className="boldforetak">10. Risikostyring og internkontroll                </h4>

                <p className="textnormal">Styret er ansvarlig for å påse at selskapet har god intern kontroll og hensiktsmessige systemer for risikostyring tilpasset selskapets omfang og virksomhet. Konsernets viktigste risikofaktorer er markedsrisikoen knyttet til aksjemarkedet, finansiell risiko og operasjonell risiko. De ulike risikotyper beskrives og drøftes i årsberetningen, og rapporteres til styret kvartalsvis.</p>
                <p className="textnormal">I praksis håndteres risiko i virksomhetsområdene som en integrert del av arbeidsprosessene.</p>
                <p className="textnormal">Ansvaret for å utføre en tilfredsstillende intern kontroll tilligger også enhver leder i konsernet ved at risikofaktorer knyttet til virksomheten skal kartlegges og styres på en systematisk måte.</p>
                <p className="textnormal">Avdelingen for konsernregnskap utarbeider kvartalsvis konsernregnskap som inngår i den finansielle rapporteringen. Det er opprettet et revisjonsutvalg som et arbeidsutvalg for styret. Revisjonsutvalget skal påse at selskapet har gode interne rutiner og håndterer risiko knyttet til regnskap/administrasjon og utarbeidelse av finansiell rapportering på en forsvarlig måte.</p>
                <p className="textnormal">Revisjonsutvalget gjennomgår den finansielle års- og delårsrapportering, med spesielt fokus på regnskapsprinsipper, vesentlige vurderingsposter og overholdelse av lover, forskrifter og regnskapsstandarder.</p>

                <h4 className="boldforetak">11. Godtgjørelse til styret                </h4>

                <p className="textnormal">Generalforsamlingen fastsetter årlig honorar til styrets og revisjonsutvalgets medlemmer.</p>
                <p className="textnormal">Det utbetales ikke godtgjørelsen til styret, og det utstedes ikke aksjeopsjoner til styremedlemmene.</p>
                <p className="textnormal">Styret eier A-aksjene i Commandeer Capital AS, og har dermed rett på et preferanseutbytte som blir bestemt ut fra selskapets prestasjoner relativt til MSCI World Index. Dette beskrives nærmere i vedtekt §VI.</p>

                <h4 className="boldforetak">12. Godtgjørelse til ledende ansatte                </h4>

                <p className="textnormal">Det søkes å legge forholdene til rette for å rekruttere og beholde ledende ansatte med ønsket kompetanse og erfaring.</p>
                <p className="textnormal">Godtgjørelse skal være konkurransedyktig og reflektere ansvarsområde og utførelse av arbeidet. Eventuelle resultatavhengige godtgjørelser bør være prestasjonsrettede og forankret i forhold som den ansatte kan påvirke, og ikke ha fastsatt maksimal størrelse.</p>
                <p className="textnormal">På nåværende tidspunkt betales det ikke godtgjørelse til administrerende direktør, mens godtgjørelse til eventuelle andre ledende ansatte fastsettes av administrerende direktør i samråd med styrets leder.</p>
                <p className="textnormal">Styret fremlegger en erklæring om fastsettelse av lønn og annen godtgjørelse til ledende ansatte på selskapets generalforsamling, Erklæringen er i sin helhet presentert i note til årsregnskapet, hvorav det også fremgår samlede ytelser til administrerende direktør og eventuelle øvrige ledende ansatte.</p>
                <p className="textnormal">Styret fremlegger også retningslinjer for fastsettelse av godtgjørelse til ledende personer på selskapets generalforsamling. Retningslinjene finnes på selskapets nettside www.comcap.no.</p>

                <h4 className="boldforetak">13. Informasjon og kommunikasjon                </h4>

                <p className="textnormal">Commandeer Capital AS legger vekt på å presentere god og presis informasjon om selskapet til selskapets eiere og andre interessenter. Det er utarbeidet retningslinjer for rapportering av finansiell og annen informasjon til konsernets ulike interessegrupper. Retningslinjene omhandler også konsernets kontakt med aksjonærer utenfor generalforsamlingen, og er basert på åpenhet og hensyn til likebehandling av aktørene i markedet.</p>
                <p className="textnormal">Informasjon til selskapets aksjeeiere legges ut på selskapets nettside (www.comcap.no) samtidig med annen offentliggjøring.</p>
                <p className="textnormal">En oversikt over datoer for viktige hendelser som generalforsamling, offentliggjøring av delårsrapporter, osv. publiseres også på selskapets nettside.</p>


                <h4 className="boldforetak">14. Selskapsovertakelse                </h4>

                <p className="textnormal">Det finnes ingen begrensninger i selskapets vedtekter vedrørende kjøp av aksjer i Commandeer Capital AS.</p>
                <p className="textnormal">Styret i Commandeer Capital AS vil håndtere eventuelle overtagelsestilbud i samsvar med prinsippet om likebehandling av aksjonærer, og bidra til at virksomheten ikke forstyrres unødig. Samtidig vil styret bidra til at aksjonærene får mest mulig fullstendig informasjon i alle situasjoner som påvirker aksjonærenes interesser, herunder fremlegge verdivurdering fra en uavhengig sakkyndig.</p>
                <p className="textnormal">Aksjonærene skal også få rimelig tid til å kunne ta stilling til budet.</p>
                <p className="textnormal">Styret vil ikke uten særlig grunn forhindre at noen fremsetter tilbud på selskapets virksomhet eller aksjer. Dersom det fremsettes et tilbud på selskapets aksjer, vil ikke styret utnytte emisjonsfullmakter eller treffe andre tiltak med formål å hindre gjennomføringen av tilbudet etter at dette er kjent.</p>
                <p className="textnormal">Styret vil avgi uttalelse med anbefaling hvorvidt aksjeeierne bør akseptere budet eller ikke, herunder opplyse om tilslutningen til slik anbefaling blant styremedlemmene.</p>
                <p className="textnormal">Transaksjoner som i realiteten innebærer avhendelse av virksomheten, skal besluttes av generalforsamlingen.</p>


        </div>
        <div style={divMargin}>

        </div>
        <Disclaimer></Disclaimer>
        </div>
    );
}

export default Foretaksstyring;