import React from 'react';
import Navigation from '../components/Navigation';
import ProfileCard from '../components/ProfileCard';
import Disclaimer from '../components/Disclaimer';
import ReportsSection from '../components/ReportsSection';

import H12024 from '../assets/reports/H12024.pdf';
import H12023 from '../assets/reports/H12023.pdf';
import FY2023 from '../assets/reports/FY2023.pdf';
import H12022 from '../assets/reports/H12022.pdf';
import FY2022 from '../assets/reports/FY2022.pdf';
import H12021 from '../assets/reports/H12021.pdf';
import FY2021 from '../assets/reports/FY2021.pdf';
import Vedtekter2023 from '../assets/reports/Vedtekter-2023.pdf';
import Honorar2023 from '../assets/reports/Retningslinjer-Godtgjørelse-2023.pdf';


const Rapporter = () => {
    // Component logic and JSX goes here
    const divMargin = {
        marginTop: '5%',   // 5% top margin
        marginBottom: '20%',   // 5% top margin
    
    };
    const divStyle = {
        marginTop: '10px',   // 5% top margin
        marginLeft: '22.5%',
        marginRight: '22.5%',
        textAlign: 'left',
        fontFamily: 'Montserrat, sans-serif', // Montserrat font
        lineHeight: 1.8, // Line spacing
        fontSize: '16px', // Text size // Add Montserrat font
        color: '#666', // Dark grey text color
        letterSpacing: '0.5px', // Slight increase in letter spacing
        padding: '10px 0', // Adds vertical padding to the div
        marginBottom: '5px',   // 5% top margin

    };
    const reports = [
        { id: 7, title: 'H1 2024 Report', date: 'Jul-2024', fileUrl: H12024 },

        { id: 6, title: 'FY 2023 Report', date: 'Jan-2024', fileUrl: FY2023 },
        { id: 5, title: 'H1 2023 Report', date: 'Jul-2023', fileUrl: H12023 },

        { id: 4, title: 'FY 2022 Report', date: 'Jan-2023', fileUrl: FY2022 },
        { id: 3, title: 'H1 2022 Report', date: 'Jul-2022', fileUrl: H12022 },

        { id: 2, title: 'FY 2021 Report', date: 'Jan-2022', fileUrl: FY2021 },
        { id: 1, title: 'H1 2021 Report', date: 'Jul-2021', fileUrl: H12021 },
        // ... other reports
      ];
      const vedtekterfiler = [
        { id: 1, title: 'Vedtekter 2023', date: '2023', fileUrl: Vedtekter2023 },

        // ... other reports
      ];
      const honorarFiler = [
        { id: 1, title: 'Rettningslinjer Godgjørelse 2023 ', date: '2023', fileUrl: Honorar2023 }

        // ... other reports
      ];
    return (
        <div>
            <div>
               <Navigation />
            </div>
            <div >
              <ReportsSection reports={vedtekterfiler} SeksjonsHeader= 'Vedtekter Commandeer Capital AS'/>
            </div>
            <div >
              <ReportsSection reports={honorarFiler} SeksjonsHeader= 'Retningslinjer for godtgjørelse Commandeer Capital AS'/>
            </div>
            <div >
              <ReportsSection reports={reports} SeksjonsHeader={'Rapporter Commandeer Capital AS'} />
            </div>
          

              <Disclaimer></Disclaimer>
        </div>
    );
}

export default Rapporter;