import React from 'react';
import Navigation from '../components/Navigation';
import Disclaimer from '../components/Disclaimer';
import Kielland from '../assets/images/Kielland.png';
import QuoteComponent from '../components/QuoteComponent/QuoteComponent';
import './DefaultText.css';

const centeredContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};



const OmComCap = () => {
    // Component logic and JSX goes here

    const isMobile = () => window.innerWidth <= 768;

    return (
        <div>
        <div >

            <Navigation />
            
            <div >

            <p className="boldnormal">OM COMMANDEER CAPITAL AS   </p>
            <p className="textnormal"> I Commandeer Captital AS («COMCAP») tar vi en unik og genuin tilnærming til investeringer, basert på en dyp forståelse av både bedrifter og menneskelig atferd. Vi vet at investeringsverdenen ikke alltid gir umiddelbare belønninger og tilbakemeldinger, men vi tror at med riktig innsikt og strategi kan vi oppnå attraktiv avkastning over tid.</p>
<p className="textnormal">I hjertet av COMCAPs filosofi ligger erkjennelsen av at konkurranse i seg selv noen ganger kan være hinderet for suksess. Vi vet at mennesker har en tendens til å etterligne hverandre, selv når det fører til økonomiske nedturer. Derfor leter vi etter verdifulle muligheter i mindre konkurranseutsatte markeder, både for investorer og bedrifter, der vi kan oppnå høyere avkastning på lang sikt.

</p>
<p className="textnormal">Vi ser klart at intens konkurranse ofte kun fører til marginale gevinster og snevrer inn vårt fokus. I COMCAP ønsker vi å ha et bredere perspektiv og være årvåkne for de mulighetene som ikke er åpenbare for alle. Vår tilnærming er grundig, veloverveid og bygger på informasjon med lang holdbarhet.

</p>
<p className="textnormal">COMCAP er en dristig utfordrer i investeringsverdenen. Vi tror at suksess i investeringer krever innovasjon og mot til å gå imot strømmen. Vår konsentrerte og omtenksomme tilnærming til investeringsporteføljen, med lav omsetning og lave driftskostnader, sikrer maksimal langsiktig avkastning for våre partnere. Vi deler både oppturer og nedturer med våre partnere og jobber sammen mot et felles mål om å oppnå en attraktiv rentefot på substansverdi over tid.

</p>
<p className="textnormal">I en verden der det er fristende å følge flertallet, inviterer COMCAP deg til å tenke annerledes og handle ut fra dine indre verdier. Vår tilnærming til investeringer er informert, innsiktsfull og langsiktig. Vi ser frem til å bygge sterke partnerskap og skape reell verdi sammen med våre partnere.

</p>
</div>
        
        <div style={{ display: 'flex', justifyContent: 'center', padding: isMobile() ? '20px' : '50px'} } className="textnormal">
<QuoteComponent
  imageSrc={Kielland}
  diameter= "275px"
  textList={['«Skipper Worse kunne ikke lenger styre seg, han tok tak i vantet, svang seg opp på rekken, og idet han viftet med hatten, ropte han, så det lød over hele Sandsgaard:', 'Vi kommer sent - Hr. Kunsel! men vi kommer godt!»', '- Fra første kapittel av "Skipper Worse", Alexander Kielland, 1882.']}/>

        </div>

        </div>
        
         <Disclaimer className="textnormal"></Disclaimer>
         </div>
    );
}

export default OmComCap;