import React, { useState, useEffect, useRef } from 'react';

function BackgroundMusicPlayer({ songs }) {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true); // Set to true by default
  const [volume, setVolume] = useState(0.05); // Default volume
  const audioRef = useRef(new Audio(songs[0]));

  const playPauseToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const changeVolume = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const playNextSong = () => {
    const nextIndex = (currentSongIndex + 1) % songs.length;
    setCurrentSongIndex(nextIndex);
    setIsPlaying(true);
  };

  useEffect(() => {
    audioRef.current.src = songs[currentSongIndex];
    if (isPlaying) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Playback was successful.
        }).catch(error => {
          console.error('Playback failed:', error);
          setIsPlaying(false); // Handle error, possibly by muting or showing UI feedback
        });
      }
    }
  }, [currentSongIndex, songs]);

  useEffect(() => {
    audioRef.current.addEventListener('ended', playNextSong);
    return () => {
      audioRef.current.removeEventListener('ended', playNextSong);
    };
  }, [playNextSong]);

  // Attempt to play music as soon as the component mounts
  useEffect(() => {
    audioRef.current.play().catch(error => {
      console.log("Autoplay was prevented by the browser.");
      setIsPlaying(false); // Optionally update UI to reflect that autoplay was blocked
    });
  }, []);

  return (
    <div>
      <button onClick={playPauseToggle}>{isPlaying ? 'Pause' : 'Play'}</button>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={changeVolume}
      />
      <button onClick={playNextSong}>Next Song</button>
    </div>
  );
}

export default BackgroundMusicPlayer;
