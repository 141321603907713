import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import Disclaimer from '../components/Disclaimer';
import BackgroundMusicPlayer from '../components/Audio/BackgroundMusicPlayer'; // Assuming this is the file containing the component
import song1 from '../assets/audio/bettery-day.mp3';
import song2 from '../assets/audio/coverless-book.mp3';
import song3 from '../assets/audio/perfect-beauty.mp3';



const AnotherPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);

const fetchMessages = () => {
  console.log('Fetching messages...');
  fetch('https://commandeer.capital:5000/get-messages')
    .then(response => {
      console.log('Response received from get-messages:', response);
      return response.json();
    })
    .then(data => {
      console.log('Data received:', data);
      setMessages(data);
    })
    .catch(error => console.error('Error fetching messages:', error));
};
  useEffect(() => {
  fetchMessages();
  const interval = setInterval(fetchMessages, 1000);
  return () => {
    console.log('Clearing interval for fetching messages');
    clearInterval(interval);
  };
}, []);

const handleInputChange = (e) => {
  console.log('Input changed:', e.target.value);
  setInputValue(e.target.value);
};

const songs = [
  song3,
  song1,
  song2
];

  const handleButtonClick = () => {
  console.log('Sending POST request with:', inputValue);
  fetch('https://commandeer.capital:5000/save-text', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: inputValue }),
  })
  .then(response => {
    console.log('Response received from save-text:', response);
    return response.text();
  })
  .then(data => {
    console.log('Server response:', data);
    alert(`Server response: ${data}`);
  })
  .catch((error) => {
    console.error('Error sending message:', error);
  });
};
  return (
    <div>
    <div>
      <Navigation />
      <div className="App">
      <h1>Background Music Player</h1>
      <BackgroundMusicPlayer songs={songs} />
    </div>
      <h1>My Test2 Buttons and Inputs</h1>
      <input 
        type="text" 
        value={inputValue} 
        onChange={handleInputChange} 
        placeholder="Enter something"
      />
      <button onClick={handleButtonClick}>Send</button>
      <h2>Last 5 Messages</h2>
      <ul>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
     <Disclaimer></Disclaimer>
     </div>
  );
};

export default AnotherPage;
