// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reports-section {
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .reports-section h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .reports-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .report-item {
    background-color: white;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .report-item h3 {
    margin-top: 0;
  }
  
  .report-item a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .report-item a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ReportsSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".reports-section {\r\n    padding: 20px;\r\n    background-color: #f4f4f4;\r\n  }\r\n  \r\n  .reports-section h2 {\r\n    color: #333;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .reports-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n  }\r\n  \r\n  .report-item {\r\n    background-color: white;\r\n    padding: 15px;\r\n    border: 1px solid #ddd;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .report-item h3 {\r\n    margin-top: 0;\r\n  }\r\n  \r\n  .report-item a {\r\n    color: #0066cc;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .report-item a:hover {\r\n    text-decoration: underline;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
