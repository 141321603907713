import React from 'react';
import './ReportsSection.css';

const ReportItem = ({ report }) => (
  <div className="report-item">
    <h3>{report.title}</h3>
    <p>Publisert: {report.date}</p>
    <a href={report.fileUrl} download>Link</a>
  </div>
);

const ReportsSection =({ reports, SeksjonsHeader }) => (
  <section className="reports-section">
    <h2>{SeksjonsHeader}</h2>
    <div className="reports-container">
      {reports.map(report => <ReportItem key={report.id} report={report} />)}
    </div>
  </section>
);

export default ReportsSection;
