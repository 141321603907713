// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navigation.css */



.navLink {
  /* ... other styles ... */
  transition: color 0.3s; /* Transition for text color */
}

.navLink:hover, .navLink:focus {
  color: white; /* Text color changes to white on hover */
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation.css"],"names":[],"mappings":"AAAA,mBAAmB;;;;AAInB;EACE,yBAAyB;EACzB,sBAAsB,EAAE,8BAA8B;AACxD;;AAEA;EACE,YAAY,EAAE,yCAAyC;EACvD,eAAe;AACjB","sourcesContent":["/* Navigation.css */\r\n\r\n\r\n\r\n.navLink {\r\n  /* ... other styles ... */\r\n  transition: color 0.3s; /* Transition for text color */\r\n}\r\n\r\n.navLink:hover, .navLink:focus {\r\n  color: white; /* Text color changes to white on hover */\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
