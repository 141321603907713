import React from 'react';
import Navigation from '../components/Navigation';
import ProfileCard from '../components/ProfileCard';
import Disclaimer from '../components/Disclaimer';
import Rockefeller from '../assets/images/Rockefeller.png';
import QuoteComponent from '../components/QuoteComponent/QuoteComponent';
import './DefaultText.css';

const centeredContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const Partner = () => {
    // Component logic and JSX goes here
    const isMobile = () => window.innerWidth <= 768;

    const divMargin = {
        marginTop: isMobile() ? '5%' : '5%',  
        marginBottom: isMobile() ? '5%' : '20%',   
    
    };

    //Dynamic updating the date of handelsoppdatering.
    const today = new Date();
    //const today = new Date("2026-01-16T12:00:00");

    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // January is 0
    const currentDay = today.getDate();
  
    let h1Text;
    let sendesTilText;
  
    // January Pre FY (consistent FY and "January" logic)
    if ((currentMonth === 0 && currentDay <= 15)) {
      h1Text = `Handelsoppdatering FY-${currentYear-1}`;
      sendesTilText = `Sendes til partnere i løpet av januar ${currentYear }.`;
    }
    // After January 15, untill july 15.
    else if (currentMonth < 6 || currentMonth === 6 && currentDay <= 15) {
      h1Text = `Handelsoppdatering H1-${currentYear}`;
      sendesTilText = `Sendes til partnere i løpet av juli ${currentYear}.`;
    }
    // After July 15, reset to "FY" and next January
    else {
      h1Text = `Handelsoppdatering FY-${currentYear}`;
      sendesTilText = `Sendes til partnere i løpet av januar ${currentYear + 1}.`;
    }

    return (
        <div>
        <div>
          <Navigation />

            <h1 className="boldcentered">{h1Text}</h1>
            <p></p>
            <p className="textnormalcentered">{sendesTilText}</p>
            <p className="textnormalcentered">
              <strong>Spørsmål?</strong> Kontakt oss på:{" "}
              <strong>Partner@comcap.no</strong>
            </p>

            <p></p>
            <div style={divMargin}> 




        </div>
        <div style={{ display: 'flex', justifyContent: isMobile() ?'left': 'center', padding: isMobile() ? '20px' : '50px'} } className="textnormal">
<QuoteComponent
  imageSrc={Rockefeller}
  diameter="275px"
  textList={['«Jeg tror ikke det er noen annen kvalitet som er så viktig for suksess av noe slag som utholdenhet. Den overvinner nesten alt, til og med naturen.»', '- John D. Rockefeller, Oljemagnat']}/>
        </div>

        </div>
        <div>
        <Disclaimer></Disclaimer>
        </div>
        </div>
    );
}

export default Partner;