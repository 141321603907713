import React from 'react';
import Navigation from '../components/Navigation';
import ProfileCard from '../components/ProfileCard';
import Sindre from '../assets/images/Sindre.png';
import Knut from '../assets/images/Knut.png';
import Martin from '../assets/images/Martin.png';
import Disclaimer from '../components/Disclaimer';
import logoship from '../assets/images/logoship.png';
import QuoteComponent from '../components/QuoteComponent/QuoteComponent';
import AndrewCarnegie from '../assets/images/Carnegie.png';
import './DefaultText.css';

const centeredContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};
const isMobile = () => window.innerWidth <= 768;


const Styret = () => {
    // Component logic and JSX goes here
    return (
      <div>
            <div>
            <Navigation />
            <p className="textnormalcentered">Styret i COMCAP består i 2024 av tre personer.</p>
            <p className="textnormalcentered">Alle styremedlemmene var medvirkende i selskapets grunnleggelse.</p>

            <div style={profileCardStyle.container}>
            <ProfileCard
  imageUrl={Sindre} // Erstatt med faktisk bildesti
  name="Sindre Nervik Grønås"
  title="Ledende Partner og Medeier"
  company="Commandeer Capital AS"
  description="Sindre Nervik Grønås er utdannet siviløkonom ved Norges Handelshøyskole.
  Hr. Nervik Grønås har vært styremedlem i Commandeer Capital siden 2021.
  Per 01.03.2024 eide Sindre Nervik Grønås 7 500 A-aksjer og 202 877 B-aksjer."
  email="sng@comcap.no" // Erstatt med faktisk e-postadresse
  phone="+47 99 55 13 78" // Erstatt med faktisk telefonnummer
/>
    </div>
    <div style={profileCardStyle.container}>

    <ProfileCard
  imageUrl={Martin} // Erstatt med faktisk bildesti
  name="Kjell Martin Dagsvik"
  title="Ledende Partner og Medeier"
  company="Commandeer Capital AS"
  description="Kjell Martin Dagsvik er utdannet sivilingeniør ved Norges Teknisk-naturvitenskapelige Universitet.
  Hr. Dagsvik har vært styrets nestleder i Commandeer Capital siden 2021.
  Per 01.03.2024 eide Kjell Martin Dagsvik 7 500 A-aksjer og 221 337 B-aksjer."
  email="kmd@comcap.no" // Erstatt med faktisk e-postadresse
  phone="+47 93 03 17 80" // Erstatt med faktisk telefonnummer
/>
</div>
<div style={profileCardStyle.container}>
<ProfileCard
  imageUrl={Knut} // Erstatt med faktisk bildesti
  name="Knut Martin Karlsen"
  title="Ledende Partner og Medeier"
  company="Commandeer Capital AS"
  description="Knut Martin Karlsen er utdannet fiskehelsebiolog ved Universitetet i Bergen.
  Hr. Karlsen har vært styreleder og daglig leder i Commandeer Capital siden 2021.
  Per 01.03.2024 eide Knut Martin Karlsen 15 000 A-aksjer og 721 106 B-aksjer."
  email="kmk@comcap.no" // Erstatt med faktisk e-postadresse
  phone="+47 40 45 68 15" // Erstatt med faktisk telefonnummer
/>
</div>



<div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px'} } className="textnormal">
<QuoteComponent
  imageSrc={AndrewCarnegie}
  diameter="275px"
  textList={['«Jeg legger mindre vekt på hva folk sier. Jeg ser heller på hva de gjør.»', '- Andrew Carnegie, stålmagnat']}
/>

</div>

        </div>
         <Disclaimer></Disclaimer>
         </div>
    );
}
const profileCardStyle = {
    container: {
      display: 'flex',
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      height: isMobile ? 'auto' : 'auto', // Take up full viewport height
      margin: '20px',
      
    }
  };
export default Styret;