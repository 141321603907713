import React, { useEffect, useRef } from 'react';
import './Disclaimer.css';
import logoship from '../assets/images/logoship.png';
import '../pages/DefaultText.css';



const Disclaimer = () => {
  const isMobile = () => window.innerWidth <= 768;

  const textRef = useRef(null);
  const imageRef = useRef(null);
  const divMargin = {
    marginTop: '100px',   // 5% top margin
    marginBottom: '2px',   // 5% top margin
    backgroundColor: isMobile() ?'rgba(60,60,60,0.2)':'rgba(60,60,60,0.1)',   // Example: light grey background color


};


  useEffect(() => {
    if (textRef.current && imageRef.current) {
      const textHeight = textRef.current.offsetHeight;
      imageRef.current.style.height = `${textHeight}px`;
    }
  }, []);
  return (
    <div style={divMargin}>
                    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans&display=swap" rel="stylesheet"/>

    <div className="disclaimer-container">
      <img ref={imageRef} src={logoship} alt="Commandeer Logo" className="disclaimer-logo" />
      <div ref={textRef} className="disclaimer-text">

        <p className="textnormaldisclaimer">Ansvarsfraskrivelse</p>
        <p className="textnormaldisclaimer">
          
          Commandeer Capital er et lukket investeringsselskap og informasjonen på denne nettsiden er kun tilpasset profesjonelle investorer.
          Informasjonen er kun gitt for informasjonsformål og kan ikke tolkes som et tilbud om å selge eller en anmodning om å kjøpe en eierandel i Commandeer Capital eller noen av dets investerte enheter.
          Informasjonen på denne nettsiden kan ikke reproduseres eller distribueres uten Commandeer Capitals forutgående skriftlige samtykke.
        </p>
        </div>
        </div>
     
        <p className="textdisclaimer"  >
          Commandeer Capital AS | Øvre Sædalsvegen 10 | 5099 Bergen | Norway
        </p>
        <p className="textdisclaimer" >
          Copyright © 2021 - 2024 All Rights Reserved
        </p>
      
    </div>

  );
};

export default Disclaimer;
