import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AnotherPage from './pages/AnotherPage';
import OmComCap from './pages/OmComCap';
import Partner from './pages/Partner';
import Styret from './pages/Styret';
import Foretaksstyring from './pages/Foretaksstyring';
import Rapporter from './pages/Rapporter';
import Login from './components/Login';
import Register from './components/Register';

import './App.css';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Check for token in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setLoggedIn(false);
  };

  return (
    <Router>
    <div className="App">

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/om-comcap" element={<OmComCap />} />
        <Route path="/Partner" element={<Partner />} />
        <Route path="/Styret" element={<Styret />} />
        <Route path="/foretaksstyring" element={<Foretaksstyring />} />
        <Route path="/dokumenter" element={<Rapporter />} />
        <Route path="/AnotherPage" element={<AnotherPage />} />
        <Route path="/login" element={<Login onLogin={setLoggedIn} />} />
          <Route path="/register" element={<Register />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
