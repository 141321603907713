// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quote-container {
    display: flex;
    align-items: center; /* This will vertically center the text to the image */
    font-family: "Montserrat","Open Sans","Helvetica Neue","Helvetica","sans-serif";
    font-size: '16px';
    color: '#666';
  }
  
  .text-container {
    margin-left: 10px; /* Adjust the spacing to your preference */
    max-width: 500px;
    font-family: "Montserrat","Open Sans","Helvetica Neue","Helvetica","sans-serif";
    font-size: '16px';
    color: '#666';


  }
  .combined-container {
    margin-left: 10px; /* Adjust the spacing to your preference */
    max-width: 500px;
    font-family: "Montserrat","Open Sans","Helvetica Neue","Helvetica","sans-serif";
    font-size: '16px';
    color: '#666';


  }
  
  .text-container p {
    margin: 25px 0; /* Small spacing between each line */
    text-align: left; /* Horizontally center to the left */
    font-family: "Montserrat","Open Sans","Helvetica Neue","Helvetica","sans-serif";
    font-size: '16px';
    color: '#666';

  }
  `, "",{"version":3,"sources":["webpack://./src/components/QuoteComponent/QuoteComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,sDAAsD;IAC3E,+EAA+E;IAC/E,iBAAiB;IACjB,aAAa;EACf;;EAEA;IACE,iBAAiB,EAAE,0CAA0C;IAC7D,gBAAgB;IAChB,+EAA+E;IAC/E,iBAAiB;IACjB,aAAa;;;EAGf;EACA;IACE,iBAAiB,EAAE,0CAA0C;IAC7D,gBAAgB;IAChB,+EAA+E;IAC/E,iBAAiB;IACjB,aAAa;;;EAGf;;EAEA;IACE,cAAc,EAAE,oCAAoC;IACpD,gBAAgB,EAAE,oCAAoC;IACtD,+EAA+E;IAC/E,iBAAiB;IACjB,aAAa;;EAEf","sourcesContent":[".quote-container {\r\n    display: flex;\r\n    align-items: center; /* This will vertically center the text to the image */\r\n    font-family: \"Montserrat\",\"Open Sans\",\"Helvetica Neue\",\"Helvetica\",\"sans-serif\";\r\n    font-size: '16px';\r\n    color: '#666';\r\n  }\r\n  \r\n  .text-container {\r\n    margin-left: 10px; /* Adjust the spacing to your preference */\r\n    max-width: 500px;\r\n    font-family: \"Montserrat\",\"Open Sans\",\"Helvetica Neue\",\"Helvetica\",\"sans-serif\";\r\n    font-size: '16px';\r\n    color: '#666';\r\n\r\n\r\n  }\r\n  .combined-container {\r\n    margin-left: 10px; /* Adjust the spacing to your preference */\r\n    max-width: 500px;\r\n    font-family: \"Montserrat\",\"Open Sans\",\"Helvetica Neue\",\"Helvetica\",\"sans-serif\";\r\n    font-size: '16px';\r\n    color: '#666';\r\n\r\n\r\n  }\r\n  \r\n  .text-container p {\r\n    margin: 25px 0; /* Small spacing between each line */\r\n    text-align: left; /* Horizontally center to the left */\r\n    font-family: \"Montserrat\",\"Open Sans\",\"Helvetica Neue\",\"Helvetica\",\"sans-serif\";\r\n    font-size: '16px';\r\n    color: '#666';\r\n\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
